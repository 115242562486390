<template>
  <section class="home-block py-0 text-white">
    <div class="background">
      <div
        ref="background"
        class="video-background"
        >
        <video
          v-if="content.video"
          autoplay
          muted
          loop
          playsinline
          :src="content.video"
        />
        <picture v-else-if="content.images">
          <source
            v-for="(image, idx) in content.images.srcSet"
            :key="idx"
            type="image/webp"
            :srcset="image.src"
            :media="`(min-width: ${image.media}px)`"
          />
          <img
            :src="content.images.default"
            :alt="content.images.alt"
            loading="lazy"
          />
        </picture>
      </div>
    </div>
    <div ref="particle" class="particle" />
    <div class="content">
      <div ref="content" class="container">
        <PlayCamera v-if="allowPlayCamera" />
        <slot name="top" />
        <div v-if="label" class="label">
          <div v-if="labelLink" class="h5">
            <router-link
              class="stretched-link"
              :to="{ name: labelLink }"
              v-html="label"
            />
          </div>
          <div v-else class="h5" v-html="label" />
        </div>
        <Breadcrumbs v-if="breadcrumbs" :links="breadcrumbs" />
        <component
          :is="titleTag"
          v-if="!content.link"
          class="title"
          :class="{ h1: titleTag == 'div' }"
          v-html="content.title"
        ></component>
        <component
          :is="titleTag"
          v-else
          class="title"
          :class="{ h1: titleTag == 'div' }"
          ><router-link
            :to="{ name: content.link }"
            v-html="content.title"
          ></router-link
        ></component>
        <div v-if="content.text" class="text" v-html="content.text" />
        <slot name="btns" />
        <slot name="bottom" />
        <div v-if="content.more" class="more">
          <a href="#" @click.prevent="scrollDown" v-html="content.more" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PlayCamera from "@/components/PlayCamera.vue";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default {
  components: {
    PlayCamera,
    Breadcrumbs,
  },
  props: {
    content: {
      type: Object,
    },
    label: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    labelLink: {
      type: String,
      required: false,
    },
    breadcrumbs: {
      type: Array,
      required: false,
    },
    titleTag: {
      type: String,
      default: "h1",
    },
  },
  computed:{
    allowPlayCamera() {
      return this.$route.name == "Home";
    }
  },
  mounted() {
    if (!is_touch()) {
      gsap.to(this.$refs.background, {
        scale: 1.2,
        scrollTrigger: {
          trigger: this.$el,
          scrub: 0.5,
          start: "top top",
          end: "bottom top",
        },
      });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            end: "90% top",
            toggleActions: "play reverse play reverse",
          },
        })
        .from(this.$refs.particle, {
          autoAlpha: 0,
        })
        .from(
          this.$refs.content.children,
          {
            autoAlpha: 0,
            y: 20,
            stagger: { each: 0.1 },
            clearProps: "all",
          },
          0.2
        );
    }

    if (!deferthings) {
      window.addEventListener("load", () => {
        setTimeout(() => {
          lazyVideo(this.$el);
        }, 3000);
      });
    } else {
      lazyVideo(this.$el);
    }
  },
  methods: {
    scrollDown() {
      gsap.to(window, {
        scrollTo: this.$el.clientHeight,
        duration: "1",
        ease: "power2.inOut",
      });
    },
  },
};
</script>

<style scoped>
.home-block {
  background: #646d3a;
  text-align: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(104 116 69 / 0.3);
}

.particle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(~@i/bg-hero.svg) 50% 75px no-repeat;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 760px;
  padding: 110px 0;
}

:deep(.content .play-camera) {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

:deep(.content .play-camera__words) {
  filter: brightness(0) invert(1);
}

.label {
  position: relative;
  max-width: 500px;
  margin-right: auto;
  margin-bottom: 45px;
  margin-left: auto;
}

.label h5,
.label .h5 {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 7px 16px 9px;
  border-radius: 20px;
  background: #509c3b;
  font-weight: bold;
}

.label h5 a,
.label .h5 a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .label h5 a:hover,
body.-notouch .label .h5 a:hover {
  text-decoration: underline;
}

.title {
  max-width: 1030px;
  margin-right: auto;
  margin-left: auto;
}
.title a {
  text-decoration: none;
}
body.-notouch .title a:hover {
  text-decoration: underline;
  text-decoration-thickness: 5px;
}

.text {
  max-width: 800px;
  margin: 0 auto;
  font-size: 20px;
}

.btns-list {
  margin-top: 57px;
}

.more {
  position: absolute;
  bottom: 35px;
  left: 0;
  width: 100%;
  font-size: 16px;
  text-align: center;
}

.more a {
  text-decoration: none;
  opacity: 0.8;
}

body.-notouch .more a:hover {
  opacity: 1;
}

@media (max-width: 1399px) {
  :deep(.content .play-camera) {
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 992px) {
    .title {
        margin-top: 110px;
    }
}

@media (max-width: 767px) {
  .content {
    height: 810px;
    min-height: 500px;
    max-height: 100vh;
  }

  .more {
    position: static;
    margin-top: 24px;
  }
  :deep(.content .play-camera) {
    --size: 140px;

    top: 76px;
    left: 0;
  }

  .text {
    font-size: 16px;
  }

  .label {
    margin-bottom: 27px;
  }

  .label h5,
  .label .h5 {
    padding: 8px 16px;
    font-size: 12px;
  }

  .title {
    margin-bottom: 27px;
    margin-top: 130px;
  }

  :slotted(.btns-list__item) {
    margin: 0 0 10px;
  }

  :slotted(.btns-list__item:last-child) {
    margin-bottom: 0;
  }

  .particle {
    background-size: 770px auto;
  }
}
@media (max-width: 575px) {
  :slotted(.btns-list .btn-transparent) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.breadcrumbs {
  justify-content: center;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .breadcrumbs {
    margin-bottom: 30px;
    font-size: 16px;
  }
}
</style>
