<template>
	<section class="home-block">
    <div class="container">
      <div class="header">
        <h2 ref="title" class="h2 title text-center">1 очередь - выдача ключей</h2>
        <h4 class="h4 subtitle text-center">Сравните рендеры и живые фотографии</h4>
      </div>
      <div class="slider-wrapper">
        <swiper
          navigation
          :space-between="10"
          :slides-per-view="1"
          :thumbs="{ swiper: thumbsSwiper }"
        >
          <swiper-slide
            v-for="slide in slides"
            :key="slide.id"
            class="swiper-no-swiping"
          >
            <ComparisonSlider>
              <template v-slot:before>
                <img :src="slide.render.src" :alt="slide.render.alt">
              </template>
              <template v-slot:after>
                <img :src="slide.photo.src" :alt="slide.photo.alt">
              </template>
            </ComparisonSlider>
          </swiper-slide>
        </swiper>
      </div>
      <div class="thumb-wrapper">
        <swiper
          class="thumb"
          :space-between="8"
          slides-per-view="auto"
          watch-slides-visibility
          watch-slides-progress
          @swiper="setThumbsSwiper"
        >
          <swiper-slide
            v-for="slide in slides"
            :key="slide.id"
          >
            <div class="thumb__slide-wrapper">
              <img
                class="thumb__img"
                :src="slide.render.src"
                :alt="slide.render.alt"
              >
              <div class="thumb__overlay">
                <div class="thumb__title">
                  {{ slide.name }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
	</section>
</template>

<script>
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Thumbs]);

import ComparisonSlider from '@/components/ComparisonSlider.vue'

export default {
  name: 'Comparison',
  components: {
    ComparisonSlider,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      thumbsSwiper: null,
      slides: [
        {
          id: 0,
          name: 'Фасад 1',
          render: {
            src: require('@/assets/i/comparison-slides/facade/render-1.jpg'),
            alt: 'render 1'
          },
          photo: {
            src: require('@/assets/i/comparison-slides/facade/photo-1.jpg'),
            alt: 'photo 1'
          }
        },
        {
          id: 1,
          name: 'Фасад 2',
          render: {
            src: require('@/assets/i/comparison-slides/facade/render-2.jpg'),
            alt: 'render 2'
          },
          photo: {
            src: require('@/assets/i/comparison-slides/facade/photo-2.jpg'),
            alt: 'photo 2'
          }
        },
        {
          id: 2,
          name: 'Благоустройство 1',
          render: {
            src: require('@/assets/i/comparison-slides/improvement/render-1.jpg'),
            alt: 'render 1'
          },
          photo: {
            src: require('@/assets/i/comparison-slides/improvement/photo-1.jpg'),
            alt: 'photo 1'
          }
        },
        {
          id: 3,
          name: 'Благоустройство 2',
          render: {
            src: require('@/assets/i/comparison-slides/improvement/render-2.jpg'),
            alt: 'render 2'
          },
          photo: {
            src: require('@/assets/i/comparison-slides/improvement/photo-2.jpg'),
            alt: 'photo 2'
          }
        },
        {
          id: 4,
          name: 'Лобби 1',
          render: {
            src: require('@/assets/i/comparison-slides/lobby/render-1.jpg'),
            alt: 'render 1'
          },
          photo: {
            src: require('@/assets/i/comparison-slides/lobby/photo-1.jpg'),
            alt: 'photo 1'
          }
        },
        {
          id: 5,
          name: 'Лобби 2',
          render: {
            src: require('@/assets/i/comparison-slides/lobby/render-2.jpg'),
            alt: 'render 2'
          },
          photo: {
            src: require('@/assets/i/comparison-slides/lobby/photo-2.jpg'),
            alt: 'photo 2'
          }
        },
      ]
    }
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper
    }
  }
}
</script>

<style scoped>
.content {
  position: relative;
}

.header {
  margin-bottom: 90px;
}

.title {
  margin-bottom: 8px;
  color: #205640;
}

.slider-wrapper :deep(.swiper-slide) {
  width: 496px;
}

.btns-list {
  margin-top: 55px;
}

.slider-wrapper :deep(.swiper-button-prev),
.slider-wrapper :deep(.swiper-button-next) {
  margin-top: -40px;
}

.thumb-wrapper {
  padding-top: 24px;
}

.thumb :deep(.swiper-slide) {
  padding: 8px;
  height: 128px;
  width: 197px;
  border: 1px solid transparent;
}
.thumb .swiper-slide.swiper-slide-thumb-active {
  border: 1px solid #4D9D34;
}

.thumb__slide-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.thumb__overlay {
  display: flex;
  align-items: flex-end;
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, rgba(32, 86, 64, 0.7) 14.85%, rgba(32, 86, 64, 0) 50%);
}

.thumb__img {
  height: 100%;
  width: 100%;
}

.thumb__title {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

@media (max-width: 991px) {
  .slider-wrapper :deep(.swiper-slide) {
    width: 450px;
  }

  .header {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .slider-wrapper :deep(.swiper-slide) {
    width: 375px;
  }

  .slider-wrapper :deep(.swiper-button-prev),
  .slider-wrapper :deep(.swiper-button-next) {
    margin-top: -20px;
  }

  .header {
    margin-bottom: 32px;
  }

  .title {
    font-size: 50px;
    font-weight: 400;
  }

  .btns-list {
    padding-top: 22px;
  }

  .thumb :deep(.swiper-slide) {
    padding: 4px;
    height: 72px;
    width: 142px;
  }

  .thumb-wrapper {
    padding-top: 16px;
  }
}
@media (max-width: 575px) {
  .slider-wrapper :deep(.swiper-slide) {
    width: 300px;
  }

  .subtitle {
    font-size: 28px;
    font-weight: 400;
  }
}
@media (max-width: 374px) {
  .slider-wrapper :deep(.swiper-slide) {
    width: 290px;
  }
}

</style>
