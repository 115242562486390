// import "@css/bootstrap.css";
// import "@css/style.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import { vfmPlugin } from "vue-final-modal";
import PopupVideo from "@/components/PopupVideo.vue";
import PopupGallery from "@/components/PopupGallery.vue";
import PopupLive from "@/components/PopupLive.vue";
import PopupFloor from "@/components/PopupFloor.vue";
import PopupBuildingsPlan from "@/components/PopupBuildingsPlan.vue";
import PopupPanorama from "@/components/PopupPanorama.vue";
import PopupIT from "@/components/PopupIT.vue";
import PopupBooklet from "@/components/PopupBooklet.vue";
import PopupPromo from "@/components/PopupPromo.vue";
import PopupPromoVideo from "@/components/PopupPromoVideo.vue";
import PopupPromoCountdown from "@/components/PopupPromoCountdown.vue";
import PopupAparts from "@/components/PopupAparts.vue";
import Maska from "maska";
import Icon from "@/components/UI/Icon.vue";
import MasonryWall from "@yeger/vue-masonry-wall";
import vClickOutside from "click-outside-vue3";
import { createGtm } from "@gtm-support/vue-gtm";
import { Vue3Mq } from "vue3-mq";
import { createHead } from "@vueuse/head";
import VueCountdown from "@chenfengyuan/vue-countdown";

const app = createApp(App);

app.config.globalProperties.$titleEnd =
  " | ЖК VERY на ул Ботаническая 29 в Москве от ГК ОСНОВА";
app.config.globalProperties.$email = "office@very-botsad.ru";
app.config.globalProperties.$endpoint =
  "https://api.gkosnova.tech/public/api/v1/";
app.config.globalProperties.$project = "1";
app.config.globalProperties.$buildings = {
  1: { floors: [3, 31], isAvailable: true },
  2: { floors: [2, 31], isAvailable: true },
  4: { floors: [1, 31], isAvailable: true },
  5: { floors: [1, 31], isAvailable: false },
  6: {
    floors: [1, 5],
    isAvailable: true,
  },
};
app.config.globalProperties.$social = {
  //yt: { url: 'https://youtube.com', name: 'YouTube' },
  vk: { url: "https://vk.com/osnova_very", name: "Вконтакте" },
  tg: { url: "https://t.me/kvartal_very", name: "Телеграм" },
};

app.config.globalProperties.$createRealisteWidget = function () {
  if (!document.querySelector("#realisteWidgetScript")) {
    let realistWidgetScript = document.createElement("script");
    realistWidgetScript.setAttribute(
      "src",
      "https://script.realiste-widget.ru/widget-init.js"
    );
    realistWidgetScript.setAttribute("id", "realisteWidgetScript");
    document.body.appendChild(realistWidgetScript);
    return true;
  }
  return false;
};

app.config.globalProperties.$filters = {
  priceFormat(price) {
    const numberFormat = new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      maximumFractionDigits: 0,
    });
    return numberFormat.format(price);
  },
  squareFormat(square) {
    return square + "&nbsp;м&sup2;";
  },
  dateFormat(timestamp) {
    const months = [
      "января",
      "февраля",
      "март",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    const date = new Date(timestamp * 1000);
    return (
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
    );
  },
  phoneFormat(phone) {
    if (phone) {
      return phone.replace(/[^0-9+]/g, "");
    }
  },
  flatNameNormalize: ({ type, plural = false, lowercase = false }) => {
    const NAMES = {
      flat: {
        singular: "Квартира",
        plural: "Квартиры",
      },
      apartment: {
        singular: "Лот",
        plural: "Лоты",
      },
    };
    const form = plural ? "plural" : "singular";
    const name = NAMES[type]?.[form] ?? "";
    return lowercase ? name.toLowerCase() : name;
  },
  flatRoomNormalize: ({ type, room_count: roomCount }) => {
    switch (roomCount) {
      case "0":
      case "S": {
        return "Студия";
      }
      default: {
        const POSTFIXES = {
          flat: "комнатная",
          apartment: "комнатный",
        };
        const postfix = POSTFIXES[type];
        return postfix ? `${roomCount.replace("E", "")}-${postfix}` : "";
      }
    }
  },
  bookedStatusNormalize: (type) => {
    const STATUSES = {
      flat: "Забронирована",
      apartment: "Забронирован",
    };
    return STATUSES[type] ?? STATUSES.apartment;
  },
  mortgagePay: ({
    cost,
    initPay = cost * 0.5,
    applyCoef = false,
    coef = 1,
    percent,
    firsYearCoefficient = 0,
    years,
  }) => {
    const S = (cost - initPay) * (applyCoef ? coef : 1);
    const i = parseFloat(percent / 100 / 12);
    const n = parseFloat(years * 12);
    const P = S * ((i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));

    if (firsYearCoefficient !== 0) return Math.ceil(Math.ceil(P) / 100 * firsYearCoefficient)

    return Math.ceil(P);
  },
  declOfNum: function (number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  },
  discountArea: (data) => {
    return Math.trunc(data.layout.area * (data.discount / 100) * 10) / 10
  }
};

app.config.globalProperties.$addPlanStub = (flat) => {
  if (!flat.layout.plan_image) {
    const STUBS = {
      0: {
        image_thumb: require("@i/stubs/s_thumb.png"),
        source: require("@i/stubs/s.png"),
      },
      1: {
        image_thumb: require("@i/stubs/1_thumb.png"),
        source: require("@i/stubs/1.png"),
      },
      2: {
        image_thumb: require("@i/stubs/2_thumb.png"),
        source: require("@i/stubs/2.png"),
      },
      3: {
        image_thumb: require("@i/stubs/3_thumb.png"),
        source: require("@i/stubs/3.png"),
      },
    };
    flat.layout.plan_image = {
      ...STUBS[flat.layout.room_count.replace("S", 0).replace("E", "")],
      stub: true,
    };
  }
  return flat;
};

app.component("Icon", Icon);
app.component("PopupVideo", PopupVideo);
app.component("PopupGallery", PopupGallery);
app.component("PopupLive", PopupLive);
app.component("PopupFloor", PopupFloor);
app.component("PopupPanorama", PopupPanorama);
app.component("PopupIT", PopupIT);
app.component("PopupBooklet", PopupBooklet);
app.component("PopupBuildingsPlan", PopupBuildingsPlan);
app.component("PopupPromo", PopupPromo);
app.component("PopupPromoVideo", PopupPromoVideo);
app.component("PopupPromoCountdown", PopupPromoCountdown);
app.component("PopupAparts", PopupAparts);
app.component(VueCountdown.name, VueCountdown);

app.config.globalProperties.$openPopupWithChat = (title) => {
  const el = document.createElement("div");
  el.setAttribute("data-popup-with-chats", "");
  el.dataset["popupWithChatsTitle"] = title;
  document.body.appendChild(el);
  el.click();
  el.remove();
};

const VueProgressBarOptions = {
  color: "#205640",
  failedColor: "red",
  transition: {
    speed: "0.5s",
    opacity: "0.1s",
    termination: 300,
  },
};

const MqOptions = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1280,
  xxl: Infinity,
};

app
  .use(router)
  .use(VueProgressBar, VueProgressBarOptions)
  .use(vfmPlugin)
  .use(Maska)
  .use(MasonryWall)
  .use(vClickOutside)
  .use(Vue3Mq, MqOptions)
  .use(createHead())
  .use(
    createGtm({
      id: "GTM-K3S5WDV",
      defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: "2726c7f26c", // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    })
  )
  .mount("#app");
