<template>
  <Head>
    <title>
      ЖК VERY на Ботаническом саду в Москве — продажа квартир в новостройке от
      застройщика ГК ОСНОВА
    </title>
    <meta
      name="description"
      content="Купить новую квартиру в ЖК VERY (Вери) от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская. Цены, планировки, описание жилого комплекса, актуальная информация с официального сайта."
    />
    <meta
      property="og:title"
      content="ЖК VERY на Ботаническом саду в Москве — продажа квартир в новостройке от застройщика ГК ОСНОВА"
    />
    <meta
      property="og:description"
      content="Купить новую квартиру в ЖК VERY (Вери) от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская. Цены, планировки, описание жилого комплекса, актуальная информация с официального сайта."
    />
  </Head>
  <main class="main my-0">
    <HomeHero />
    <HomeLifestyle />
    <HomeFamily />
    <HomeCoffee />
    <HomeYard class="pb-0" />
    <HomeInside class="pb-0" />
    <HomeFlats />
    <HomeBuilding id="plans" />
    <HomeSecondFlats id="second-plans" />
    <HomeUnicLots id="unic-lots" />
    <HomeComparison />
    <!-- <HomeServiceApart id="service-apart" /> -->
    <HomeParks />
    <HomeCapital id="about" />
    <HomeRoad />
    <HomeNear />
    <HomeGallery class="pt-0 pb-0" />
    <HomeConstruction id="building" />
    <HomeTermoland id="termoland" />
    <HomeQuestions />
  </main>
</template>

<script>
import HomeHero from "@/components/home/Hero.vue";
import HomeLifestyle from "@/components/home/Lifestyle.vue";
import HomeFamily from "@/components/home/Family.vue";
import HomeCoffee from "@/components/home/Coffee.vue";
import HomeYard from "@/components/home/Yard.vue";
import HomeInside from "@/components/home/Inside.vue";
import HomeFlats from "@/components/home/Flats.vue";
import HomeSecondFlats from "@/components/home/SecondFlats.vue";
import HomeBuilding from "@/components/home/Building.vue";
import HomeParks from "@/components/home/Parks.vue";
import HomeServiceApart from "@/components/home/ServiceApart.vue";
import HomeCapital from "@/components/home/Capital.vue";
import HomeRoad from "@/components/home/Road.vue";
import HomeNear from "@/components/home/Near.vue";
import HomeGallery from "@/components/home/Gallery.vue";
import HomeConstruction from "@/components/home/Construction.vue";
import HomeQuestions from "@/components/home/Questions.vue";
import HomeUnicLots from "@/components/home/UnicLots.vue";
import HomeTermoland from "@/components/home/Termoland.vue";
import HomeComparison from "@/components/home/Comparison.vue";

import { Head } from "@vueuse/head";

// import HomeKids from '@/components/home/Kids.vue';
// import HomeParksPromo from '@/components/home/ParksPromo.vue';
// import HomeFit from '@/components/home/Fit.vue';
// import HomeFriends from '@/components/home/Friends.vue';

export default {
  components: {
    Head,
    HomeHero,
    HomeLifestyle,
    HomeFamily,
    HomeCoffee,
    HomeYard,
    HomeInside,
    HomeFlats,
    HomeSecondFlats,
    HomeBuilding,
    HomeParks,
    HomeCapital,
    HomeRoad,
    HomeNear,
    HomeGallery,
    HomeConstruction,
    HomeQuestions,
    HomeServiceApart,
    HomeUnicLots,
    HomeTermoland,
    HomeComparison,
  },
  mounted() {
    this.initNumPopup();
    this.$vfm.show(this.popups[this.numPopup]);
  },
  data() {
    return {
      numPopup: 0,
      popups: [
        {
          component: 'PopupPromoVideo',
          bind: {
            title: `
              <b style="color: #205640;">
                СЕМЕЙНАЯ ИПОТЕКА
              </b>
                <span class="second-popup__subtitle">за наш счет </span>
                <br/>
                <small>Успей купить!</small>
              </div>`,
            additionText: '',
            img: {
              default: require('@/assets/i/banners/popup_very_mob.jpg'),
              sources: [
                {
                  src: require('@/assets/i/banners/popup_very_desk.webp'),
                  media: '(min-width: 991px)'
                },
                {
                  src: require('@/assets/i/banners/popup_very_desk.jpg'),
                  media: '(min-width: 991px)'
                },
                {
                  src: require('@/assets/i/banners/popup_very_mob.webp'),
                  media: '(min-width: 0)'
                },
              ]
            },
          }
        },
      ]
    }
  },
  methods: {
    initNumPopup() {
      // if (!localStorage.getItem('numPopup')) {
      //   localStorage.setItem('numPopup', JSON.stringify(++this.numPopup))
      //   return
      // }
      // this.numPopup = JSON.parse(localStorage.getItem('numPopup'))

      // if (this.numPopup < this.popups.length - 1) {
      //   localStorage.setItem('numPopup', JSON.stringify(++this.numPopup))
      // } else {
      //   localStorage.removeItem('numPopup')
      //   this.numPopup = 0;
      // }
      this.numPopup = 0
    }
  }
};
</script>

<style>
.custom-popup .custom-popup__title {
  font-family: "Mabry", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 700;
  font-size: 29px;
  text-transform: uppercase;
}
.custom-popup .popup-promo-video__content {
  padding: 20px !important;
}
.custom-popup .custom-popup__subtitle {
  font-family: "Mabry", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: 500;
  font-size: 22px;
  /* font-size: 32px; */
  text-transform: uppercase;
}
.custom-popup .popup-promo-video__button button {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;
  color: #ED1C24 !important;
}
.custom-popup .popup-promo-video__button button:hover {
  background-color: #DDDDDD !important;
  border-color: #DDDDDD !important;
  color: #ED1C24 !important;
}
</style>
