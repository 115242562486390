<template>
  <div class="ci-slider">
    <input type="range" step="0.5" class="ci-slider__handle" v-model="amount">
    <div class="ci-slider__overlay" :style="{left: amount+'%'}">
      <div class="ci-slider__overlay__divider"></div>
      <div class="ci-slider__overlay__handle">
        <div class="ci-slider__marks ci-slider-marks">
          <div class="ci-slider-marks__item ci-slider-marks__item--left">
            <Icon icon="triangular-left" />
            <div class="ci-slider-marks__text">
              Проект
            </div>
          </div>
          <div class="ci-slider-marks__item ci-slider-marks__item--right">
            <div class="ci-slider-marks__text">
              Реальность
            </div>
            <Icon icon="triangular-right" />
          </div>
        </div>
      </div>
    </div>
    <div class="ci-slider__image ci-slider__image--before">
      <slot name="before"></slot>
      <div class="ci-slider__image-mark ci-slider__image-mark--left">
        Проект
      </div>
    </div>
    <div class="ci-slider__image ci-slider__image--after" :style="{clipPath: 'inset(0 0 0 ' + amount + '%)'}">
      <slot name="after"></slot>
      <div class="ci-slider__image-mark ci-slider__image-mark--right">
        Реальность
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ComparisonSlider',
  data() {
		return {
			amount: 50,
		}
	},
}
</script>

<style lang="css" scoped>
.ci-slider {
  --divider-thickness: 3px;
  --divider-handle-size: 120px;
  --image-mark-width: 98px;
}

.ci-slider {
  background: #eee;
  border: 0;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  position: relative;
  transform: scale(1);
  width: 100%;
}

.ci-slider__handle {
  appearance: none;
  background: none;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  outline: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.ci-slider__handle:active {
  cursor: col-resize;
}

.ci-slider__handle::-webkit-slider-thumb {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: col-resize;
  height: var(--divider-handle-size);
  width: var(--divider-handle-size);
}
.ci-slider__handle::-moz-range-thumb {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: col-resize;
  height: var(--divider-handle-size);
  width: var(--divider-handle-size);
}
.ci-slider__handle::-ms-thumb {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: col-resize;
  height: var(--divider-handle-size);
  width: var(--divider-handle-size);
}

.ci-slider__overlay {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 1;
}

.ci-slider__overlay__divider::before {
  top: 0;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.333);
  content: "";
  display: block;
  height: 100%;
  left: calc(var(--divider-thickness) / 2 * -1);
  position: absolute;
  width: var(--divider-thickness);
}

.ci-slider-marks__item {
  position: absolute;
  top: calc(50% - (var(--divider-handle-size) / 2));
  display: flex;
  align-items: center;
  gap: 8px;
  height: var(--divider-handle-size);
}

.ci-slider-marks__text {
  padding: 0px 9px;
  height: 100%;
  font-weight: 500;
  text-align: center;
  border-radius: 100px
}
@media (max-width: 767px) {
  .ci-slider-marks__text {
    display: none;
  }
}

.ci-slider-marks__item--right {
  left: 9px;
}

.ci-slider-marks__item--right .ci-slider-marks__text {
  background-color: #4D9D34;
  color: #FFFFFF;
  writing-mode: vertical-rl
}
.ci-slider-marks__item--left {
  right: 8px;
}

.ci-slider-marks__item--left .ci-slider-marks__text {
  background-color: #FFFFFF;
  color: #3D4543;
  transform: rotate(-180deg);
  writing-mode: vertical-lr;
}

.ci-slider__image > img {
  display: block;
  width: 100%;
}
.ci-slider__image::after {
  background: rgba(0, 0, 0, 0.66);
  border-radius: 5px;
  color: white;
  display: block;
  opacity: 0;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 200ms ease-out;
}
.ci-slider:hover .ci-slider__image::after {
  opacity: 1;
  transition-delay: 50ms;
}

.ci-slider__image--before {
  position: relative;
}
.ci-slider__image--before > img {
  height: auto;
}

.ci-slider__image--after {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.ci-slider__image--after > img {
  height: 100%;
}

.ci-slider__image-mark {
  display: none;
  position: absolute;
  bottom: 4px;
  padding: 9px 0px;
  width: var(--image-mark-width);
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  border-radius: 100px
}

@media (max-width: 767px) {
  .ci-slider__image-mark {
    display: block;
  }
}

.ci-slider__image-mark--left {
  left: calc(35% - var(--image-mark-width));
  background-color: #FFFFFF;
  color: #3D4543;
}

.ci-slider__image-mark--right {
  right: calc(35% - var(--image-mark-width));
  background-color: #4D9D34;
  color: #FFFFFF;
}

@media (max-width: 575px) {
  .ci-slider__image-mark--left {
    left: calc(42% - var(--image-mark-width));
  }

  .ci-slider__image-mark--right {
    right: calc(42% - var(--image-mark-width));
  }
}
</style>
