<template>
  <section class="home-block">
    <div class="content">
      <div class="container">
        <div ref="title" class="h2 title text-center">Дневник стройки</div>
        <div ref="list" class="list">
          <swiper
            v-if="filteredItems && filteredItems.length"
            class="hasPagination"
            navigation
            :slides-per-view="'auto'"
            :space-between="24"
            :pagination="{ clickable: true }"
            :preload-images="false"
            @init="onSwiper"
            @update="onSwiper"
          >
            <swiper-slide v-for="(item, i) in filteredItems" :key="item.id">
              <ConstructionItemLive v-if="i === 1" />
              <ConstructionItem v-else :item="item" />
            </swiper-slide>
          </swiper>
          <swiper v-else :slides-per-view="'auto'" :space-between="24">
            <swiper-slide v-for="(item, i) in 10" :key="i">
              <ConstructionItem />
            </swiper-slide>
          </swiper>
        </div>
        <div ref="bottom" class="btns-list d-flex justify-content-center">
          <div class="btns-list__item">
            <router-link class="btn btn-default" :to="{ name: 'Construction' }">
              Узнать больше о&nbsp;ходе&nbsp;строительства
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ConstructionItem from "@/components/home/ConstructionItem.vue";
import ConstructionItemLive from "@/components/home/ConstructionItemLive.vue";
import axios from "axios";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination]);
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  components: {
    ConstructionItem,
    ConstructionItemLive,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      items: [],
    };
  },
  computed: {
    filteredItems() {
      const res = [...this.items]
      if (res.length) res.splice(1, 0, {})
      return res
    },
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint +
          "publications?project=9&category=2&language=ru&page=1&per_page=5"
      );
      this.items = response.data.data;
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      if (!is_touch()) {
        this.$nextTick(() => {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: this.$el,
                start: "top 70%",
                end: "center center",
                scrub: 1.5,
                //markers: true,
              },
            })
            .from(
              gsap.utils.toArray([
                this.$refs.title,
                this.$refs.list,
                this.$refs.bottom,
              ]),
              {
                y: 150,
                stagger: { each: 0.1 },
              },
              0
            );

          gsap.from(this.swiper.$el[0].querySelectorAll(".item__image img"), {
            scale: 1.3,
            scrollTrigger: {
              trigger: this.swiper.$el[0],
              start: "top bottom",
              end: "40% center",
              scrub: 1.5,
              //markers: true,
            },
            stagger: { each: 0.05, ease: "none" },
          });
          gsap.from(this.swiper.$el[0].querySelectorAll(".item"), {
            x: 200,
            scrollTrigger: {
              trigger: this.swiper.$el[0],
              //start: '40% bottom',
              //end: 'center center',
              toggleActions: "play none none reverse",
            },
            stagger: { each: 0.05, ease: "none" },
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.content {
  position: relative;
}

.title {
  margin-bottom: 65px;
}

.list :deep(.swiper-slide) {
  width: 496px;
}

.list .swiper-container {
  overflow: inherit;
}

.btns-list {
  margin-top: 55px;
}

.list :deep(.swiper-button-prev),
.list :deep(.swiper-button-next) {
  margin-top: -100px;
}
@media (max-width: 991px) {
  .list :deep(.swiper-slide) {
    width: 450px;
  }

  .title {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .list :deep(.swiper-slide) {
    width: 375px;
  }

  .list :deep(.swiper-button-prev),
  .list :deep(.swiper-button-next) {
    margin-top: -60px;
  }

  .title {
    margin-bottom: 32px;
  }

  .btns-list {
    padding-top: 22px;
  }
}
@media (max-width: 575px) {
  .list :deep(.swiper-slide) {
    width: 300px;
  }
}
@media (max-width: 374px) {
  .list :deep(.swiper-slide) {
    width: 290px;
  }
}
</style>
