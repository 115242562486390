<template>
  <HeaderTopline v-if="hasTopline" />
  <header
    ref="header"
    v-click-outside="vcoConfig"
    class="header"
    :class="showDropdown || stick ? '' : $route.meta.headerClass"
  >
    <div class="container header-container">
      <div
        class="header__inner d-flex justify-content-between align-items-center"
      >
        <div class="header__left d-flex align-items-center">
          <div class="header__osnova-logo">
            <a target="_blank" href="https://gk-osnova.ru/">
              <svg xmlns="http://www.w3.org/2000/svg" width="144" height="42" viewBox="0 0 144 42" fill="none">
                <g>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3818 9.71104V32.0464L41.7294 40.2508V17.9155L33.3818 9.71104ZM32.6084 32.8066H9.88892L18.2393 41.0139H40.9587L32.6084 32.8066ZM8.33063 32.8066V8.95912L0 0.760234V41.0139H16.6923L8.34192 32.8066H8.33063ZM9.09566 8.19057H33.3734L41.7294 16.3923V0H0.773498L9.10695 8.19057H9.09566Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M54.3197 20.8204C54.3197 25.0987 55.5533 26.7496 57.7806 26.7496C60.1011 26.7496 61.2614 25.0849 61.2614 20.8204C61.2614 16.5558 60.0758 14.9189 57.7806 14.9189C55.4855 14.9189 54.3197 16.5697 54.3197 20.8204ZM65.5157 20.8204C65.5157 26.3391 62.6249 29.6685 57.7806 29.6685C52.9364 29.6685 50.0852 26.4361 50.0852 20.8204C50.0852 15.3295 52.9787 12 57.8004 12C62.622 12 65.5353 15.2074 65.5353 20.8204H65.5157Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M80.1657 13.8474L78.1898 16.1559C77.3607 15.3846 76.2654 14.9504 75.1239 14.9406C72.9446 14.9406 71.4146 16.6664 71.4146 20.795C71.4146 24.8541 72.9248 26.58 75.1973 26.58C76.5524 26.58 77.5461 25.9224 78.5369 25.1677L80.3634 27.5012C79.6589 28.1917 78.8222 28.7385 77.9016 29.1099C76.9811 29.4814 75.9948 29.6701 74.9997 29.6653C70.3023 29.6653 67.1631 26.5549 67.1631 20.7867C67.1631 15.1486 70.4774 11.9912 74.8755 11.9912C77.2017 11.9912 78.7571 12.671 80.1657 13.8391V13.8474Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M86.2227 22.0376V29.2515H82.169V12.4153H86.2227V18.9245H91.3634V12.4153H95.4201V29.2515H91.3634V22.0376H86.2227Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M101.719 20.8204C101.719 25.0987 102.956 26.7496 105.18 26.7496C107.506 26.7496 108.672 25.0849 108.672 20.8204C108.672 16.5558 107.481 14.9189 105.18 14.9189C102.88 14.9189 101.719 16.5669 101.719 20.8204ZM112.918 20.8204C112.918 26.3391 110.027 29.6685 105.177 29.6685C100.328 29.6685 97.4707 26.4361 97.4707 20.8204C97.4707 15.3295 100.361 12 105.177 12C109.994 12 112.918 15.2074 112.918 20.8204Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M120.505 26.3631C122.408 26.3631 123.771 25.9719 123.771 24.1241C123.771 22.4232 122.563 21.7906 120.804 21.7906H119.026V26.3604L120.505 26.3631ZM119.026 19.1492H120.533C122.227 19.1492 123.102 18.5166 123.102 17.1071C123.102 15.6977 122.165 15.237 120.209 15.237H119.026V19.1492ZM127.946 24.3461C127.946 28.1887 124.782 29.2542 120.479 29.2542H114.969V12.4153H119.813C124.409 12.4153 127.226 13.5556 127.226 16.7159C127.226 18.8552 125.671 19.9733 124.084 20.3367C125.99 20.7002 127.941 21.6242 127.941 24.3432L127.946 24.3461Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M136.113 15.3065L134.312 22.7423H137.92L136.113 15.3065ZM133.62 25.6223L132.754 29.2293H128.564L133.716 12.4153H138.637L143.808 29.2515H139.5L138.611 25.6445L133.62 25.6223Z" fill="white"/>
                </g>
              </svg>
            </a>
          </div>
          <div class="header__logo">
            <router-link :to="{ name: 'Home' }">
              <img
                class="-black"
                loading="lazy"
                src="@i/logo.svg"
                width="169"
                height="44"
                alt="Very"
              />
              <img
                class="-white"
                loading="lazy"
                src="@i/logo-white.svg"
                width="169"
                height="44"
                alt="Very"
              />
            </router-link>
          </div>
        </div>
        <div class="header__right d-flex align-items-center">
          <div
            v-if="favouritesStore.state.favourites.length > 0"
            class="header__favourite header-favourite d-none d-xl-block"
          >
            <router-link
              class="header-favourite__icon"
              :to="{ name: 'PlanFavourites' }"
              aria-label="Избранные квартиры"
            >
              <Icon icon="heart" />
              <div class="header-favourite__label">
                <span class="favourite-count">{{
                  favouritesStore.state.favourites.length
                }}</span>
              </div>
            </router-link>
          </div>
          <div v-if="phone.number" class="header__phone d-none d-lg-block">
            <a
              :href="'tel:' + $filters.phoneFormat(phone.number)"
              :class="phone.class"
            >
              {{ phone.number }}
            </a>
          </div>
          <div class="header__btn d-none d-lg-block">
            <router-link
              :to="{ name: 'PlanArchive' }"
              class="btn btn-default btn-small"
              :class="
                showDropdown || stick
                  ? ''
                  : $route.meta.headerClass == '-white'
                  ? 'btn-white'
                  : ''
              "
            >
              Выбрать квартиру
            </router-link>
          </div>

          <button
            class="header__burger header-burger"
            :class="{ active: showDropdown }"
            :aria-label="
              showDropdown ? 'Закрыть главное меню' : 'Открыть главное меню'
            "
            @click="showDropdown = !showDropdown"
          >
            <div class="header-burger__inner" />
          </button>
        </div>
      </div>
    </div>
    <HeaderDropdown :is-active="showDropdown" />
  </header>
</template>
<script>
import HeaderDropdown from "@/components/HeaderDropdown";
import HeaderTopline from "@/components/HeaderTopline";
//import axios from 'axios';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  components: {
    HeaderDropdown,
    HeaderTopline,
  },
  inject: ["favouritesStore", "mainStore"],
  data() {
    return {
      showDropdown: false,
      stick: false,
      headerTrigger: null,
      topLineHeight: 60,
    };
  },
  computed: {
    phone() {
      const phones = {
        "+7 (495) 021-00-09": ["PlanCommerceSearch", "CommerceFlat"],
      };
      const val = {
        number: this.mainStore.state.data?.addresses.addr_list[0].addr_phone,
        class: "comagic_phone"
      };
      for (const phone in phones) {
        if (phones[phone].includes(this.$route.name)) {
          val.number = phone;
          val.class = "";
        }
      }
      return val;
    },
    infoline() {
      return this.mainStore.state.data?.info_line;
    },
    hasTopline() {
      return this.$route.meta.hasTopline && this.infoline && this.infoline.is_active;
    },
  },
  watch: {
    hasTopline () {
      this.setLineHeight()
    },
    $route() {
      this.showDropdown = false;

      if (this.headerTrigger) {
        this.headerTrigger.kill();
      }
      this.setLineHeight()
      this.headerTrigger = ScrollTrigger.create({
        trigger: this.$refs.header,
        // trigger: "body",
        start: "0px top",
        end: this.hasTopline ? this.topLineHeight + "px top" : "1px top",
        onLeave: () => {
          this.stick = true;
        },
        onLeaveBack: () => {
          this.stick = false;
        },
        onUpdate: (self) => {
          if (this.hasTopline) {
            this.$refs.header.style.transform =
              "translateY(" +
              (this.topLineHeight - self.progress * this.topLineHeight) +
              "px)";
          }
        },
        onRefresh: (self) => {
          if (this.hasTopline) {
            this.$refs.header.style.transform =
              "translateY(" +
              (this.topLineHeight - self.progress * this.topLineHeight) +
              "px)";
          }
        },
      });
    },
  },
  mounted() {
    const nav = this.$refs.header.querySelectorAll(
      ".header-dropdown-nav a, .header-dropdown-sub a"
    );
    nav.forEach((item) => {
      item.addEventListener("click", () => {
        this.showDropdown = false;
      });
    });
  },
  methods: {
    vcoConfig() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
    setLineHeight () {
      if (this.hasTopline) {
        this.$refs.header.style.transform =
          "translateY(" + this.topLineHeight + "px)";
      } else {
        this.$refs.header.style.transform = "translateY(0px)";
      }
    }
  },
};
</script>
<style>
.header-favourite__icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding-top: 1px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.1s, border-color 0.1s;
}

.header-favourite__label {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #205640;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.1;
  text-align: center;
  transition: color 0.1s, background-color 0.1s;
}

.favourite-count {
  position: absolute;
  top: 1px;
  left: 3px;
}

.header-favourite__icon .icon-heart {
  transition: fill 0.1s, stroke 0.1s;
}

.header-favourite__icon.router-link-active {
  border-color: #205640 !important;
  background: #205640 !important;
}

.header-favourite__icon.router-link-active svg {
  stroke: #fff;
}

.header-favourite__icon.router-link-active .header-favourite__label {
  background: #fff;
  color: #3f4440;
}

body.-notouch .header:not(.-white) .header-favourite__icon:hover {
  border-color: #a6bbb3;
}

body.-notouch .header:not(.-white) .header-favourite__icon:active {
  border-color: #205640;
}
</style>
<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  transition: background 0.1s, border-color 0.1s;
}
.header.-white .header__osnova-logo svg path {
  fill: #fff;
}
.header .header__osnova-logo svg path {
  fill: #333;
}

.header.overflow {
  overflow-y: scroll;
}

.header__right {
  padding-right: 97px;
}

.header.overflow .header-dropdown {
  display: none;
}

.header__inner {
  position: relative;
  height: 75px;
}

.header__logo {
  flex: 0 0 169px;
  width: 169px;
  margin-top: -14px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.header__logo img {
  transition: opacity 0.1s;
}

.header__logo img.-white {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
@media (max-width: 767px) {
  .header__logo {
    flex: 0 0 107px;
    width: 107px;
    margin-top: -10px;
  }
}

.header__burger {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -28px;
}

@media (max-width: 767px) {
  .header__burger {
    right: 0;
    left: auto;
    margin-left: 0;
  }
}

.header-burger {
  width: 56px;
  height: 56px;
  padding: 0;
  border: 0;
  background: none;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s;
  appearance: none;
}

body.-notouch .header:not(.-white) .header-burger:hover {
  color: #205640;
}

.header-burger__inner {
  position: absolute;
  top: 50%;
  right: 0;
  width: 34px;
  height: 1px;
  background: currentColor;
  transition: transform 0.2s, width 0.2s;
}

.header-burger__inner::before {
  content: "";
  position: absolute;
  top: -12px;
  right: 0;
  width: 56px;
  height: 1px;
  background: currentColor;
  transition: transform 0.2s, top 0.2s, width 0.2s;
}

.header-burger__inner::after {
  content: "Меню";
  position: absolute;
  right: 0;
  bottom: -20px;
  transition: opacity 0.2s;
}

.header__burger.active .header-burger__inner {
  width: 36px;
  transform: rotate(-45deg) translateX(-7px) translateY(-7px);
}

.header__burger.active .header-burger__inner::before {
  top: 0;
  width: 36px;
  transform: rotate(90deg);
}

.header__burger.active .header-burger__inner::after {
  opacity: 0;
}

.header__favourite {
  margin-right: 30px;
}

.header__phone {
  font-weight: normal;
  font-size: 20px;
  letter-spacing: -0.03em;
}

.header__phone a {
  color: inherit;
  text-decoration: none;
}

.header__osnova-logo svg {
  width: 100%;
}

body.-notouch .header:not(.-white) .header__phone a:hover {
  color: #205640;
}
@media (max-width: 1279px) {
  .header__logo {
    position: relative;
    left: 0;
    transform: none;
    margin: 0 10px;
  }
}
@media (max-width: 991px) {
  .header__phone {
    /* font-size: 16px; */
  }
  .header__logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin: 0;
  }
  
}
@media (max-width: 767px) {
  .header__phone {
    margin-right: 80px;
  }
}
@media (max-width: 374px) {
  .header__phone {
    margin-right: 70px;
    font-size: 14px;
  }
}

.header__btn {
  margin-left: 40px;
}
@media (max-width: 1279px) {
  .header__btn {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .header__btn {
    margin-left: 16px;
  }

  .header__btn .btn {
    padding-right: 16px;
    padding-left: 16px;
    font-size: 16px;
  }
}

.header.-white {
  border-bottom: 1px solid rgb(229 229 229 / 0.2);
  background: transparent;
}

.header.-white .header__burger {
  color: #fff;
}

.header.-white .header__text,
.header.-white .header__phone {
  color: #fff;
}

.header.-white .header__logo img.-black {
  opacity: 0;
}

.header.-white .header__logo img.-white {
  opacity: 1;
}

.header.-white .header-favourite__icon {
  border-color: rgb(255 255 255 / 0.2);
}

body.-notouch .header.-white .header-favourite__icon:hover {
  border-color: rgb(255 255 255 / 0.4);
}

body.-notouch .header.-white .header-favourite__icon:active {
  border-color: rgb(255 255 255 / 0.6);
}

.header.-white .header-favourite__icon .icon-heart {
  stroke: #fff;
}

.header.-white .header-favourite__label {
  background: #fff;
  color: #3f4440;
}
@media screen and (min-width: 1410px) {
  .header-container {
    min-width: 1410px;
  }
}
@media screen and (max-width: 575px) {
  .header__osnova-logo {
    width: 90px;
  }
}
</style>
