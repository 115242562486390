<template>
  <svg :class="'icon-' + icon">
    <use :xlink:href="require('@i/sprite.svg') + '#icon-' + icon" />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
[class^="icon-"] {
  vertical-align: middle;
  margin: -3px 7px 0 0;
}

.btn [class^="icon-"] {
  margin-right: 10px;
}

.icon-play {
  width: 17px;
  height: 22px;
  margin: 0;
  fill: none;
  stroke: currentColor;
}

.icon-panorama-white {
  width: 40px;
  height: 40px;
  margin: 0;
  fill: none;
}

.icon-round-white {
  width: 26px;
  height: 26px;
  margin: 0;
  fill: #fff;
}

.icon-fb {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: #509c3b;
}

.icon-yt {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: #509c3b;
}

.icon-vk {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: #509c3b;
}

.icon-tg {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: #509c3b;
}

.icon-ig {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: #509c3b;
}

.icon-close {
  width: 16px;
  height: 16px;
  margin: 0;
  fill: currentColor;
}

.icon-arrow-left {
  width: 18px;
  height: 14px;
  margin: 0;
  fill: none;
  stroke: currentColor;
}

.icon-settings {
  width: 20px;
  height: 20px;
  margin: 0;
  fill: currentColor;
  stroke: none;
}

.btn-small .icon-settings {
  margin-top: -3px;
  margin-bottom: -4px;
}

.icon-darr {
  width: 9px;
  height: 13px;
  margin: 0;
  fill: none;
  stroke: currentColor;
}

.icon-north {
  width: 78px;
  height: 91px;
  margin: 0;
  fill: none;
}

.icon-feats {
  width: 9px;
  height: 16px;
  margin: 0;
  fill: #205640;
}

.icon-heart {
  width: 20px;
  height: 20px;
  margin: 0;
  fill: rgb(255 255 255 / 0);
  stroke: #205640;
}

.icon-trash {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: none;
}

.icon-doc {
  width: 20px;
  height: 24px;
  margin: 0;
  fill: none;
  stroke: #205640;
}

.icon-external {
  width: 18px;
  height: 18px;
  margin: -5px 0 0 10px;
  fill: #509c3b;
}

.icon-live {
  width: 40px;
  height: 40px;
  margin: 0;
  fill: #fff;
}

.icon-lock {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: none;
  stroke: #3f4440;
}

.icon-lock2 {
  width: 19px;
  height: 16px;
  fill: none;
  stroke: currentColor;
}

.icon-share {
  width: 24px;
  height: 24px;
  margin: 0;
  fill: none;
  stroke: #205640;
}

.icon-quote {
  width: 152px;
  height: 113px;
  margin: 0;
  fill: #ececec;
}

.icon-roller {
  width: 22px;
  height: 22px;
  margin: 0;
  fill: currentColor;
}

.icon-percent {
  width: 18px;
  height: 18px;
  margin: 0;
  fill: currentColor;
}
.icon-image {
  width: 18px;
  height: 18px;
  margin: 0;
  fill: transparent;
  stroke: #fff;
}

.icon-gift {
  width: 22px;
  height: 16px;
  fill: transparent;
}

.icon-key {
  width: 22px;
  height: 16px;
  fill: transparent;
}

.icon-triangular-left {
  width: 12px;
  height: 12px;
  margin: 0;
  fill: transparent;
}

.icon-triangular-right {
  width: 12px;
  height: 12px;
  margin: 0;
  fill: transparent;
}
</style>
